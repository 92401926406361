<template>
  <div id="mian">
    <div class="header">
      <span
        @click="navClick('批量查询')"
        :class="crumbs == '批量查询' ? 'active' : 'left'"
        >批量查询</span
      >
      <span
        @click="navClick('订单查询')"
        :class="crumbs == '订单查询' ? 'active' : 'left'"
        >订单查询</span
      >
    </div>
    <div v-if="crumbs == '批量查询'">
      <!-- 上面表单框 -->
      <div class="con_from">
        <div class="lineP">
          <div class="item">
            <i>分账商户</i>
            <el-select
              v-model="query.keyword"
              filterable
              clearable
              :filter-method="searchMchList"
              @visible-change="unfoldMchList"
              @clear="clearMchList"
              @change="changeMch"
              placeholder="分账商户名称/编号"
              :no-data-text="divisionData=='' ? '无数据':'可搜索'"
            >
              <el-option
                v-for="item in divisionData"
                :key="item.allocMchId"
                :label="item.allocMchName + ' | ' + item.allocMchId"
                :value="item.allocMchId"
              >
              </el-option>
            </el-select> 
          </div>
          <div class="item">
            <i>所属商户</i>
            <el-select
              v-model="query.mchId"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(v, i) in mchDrop"
                :key="i"
                :label="v.mchName"
                :value="v.mchId"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <i>审核状态</i>
            <el-select
              v-model="query.checkStatus"
              placeholder="全部"
            >
              <el-option label="全部" value=""></el-option>
              <el-option label="待审核" value="WAIT"></el-option>
              <el-option label="已审核" value="PASS"></el-option>
              <el-option label="已驳回" value="REJECT"></el-option>
            </el-select>
          </div>
        </div>
        <div class="lineP">
          <div class="item itemTime">
            <i>时间类型</i>
            <el-select
              v-model="query.timeType"
              style="width: 130px;"
            >
              <el-option label="申请时间" value="CREATE"></el-option>
              <el-option label="审核时间" value="CHECK"></el-option>
            </el-select>
            <span style="padding: 0 10px"></span>
            <el-date-picker
              v-model="query.startTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
            <span style="padding-left: 10px; padding-right: 10px">-</span>
            <el-date-picker
              v-model="query.endTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择时间"
            >
            </el-date-picker>
          </div>
          <div class="itembtn">
            <el-button class="searchBt" @click="getLists">查询</el-button>
            <el-button
              v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/REJECT'"  :disabled="disabledFlag"
              class="searchBt"
              @click="setStatusS(false)"
              >批量驳回</el-button
            >
            <el-button
              v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/PASS'"  :disabled="disabledFlag"
              class="searchBt" 
              @click="setStatusS(true)"
              >批量通过</el-button
            >
          </div>
        </div>
      </div>
      <!-- 中间交易账目 -->
      <div class="jiaoyi">
        <div class="jiaoyi_box" style="margin-right: 16px">
          <p><i>笔数（笔）</i></p>
          <p>
            <b>{{ totalNum }}</b>
          </p>
        </div>
        <div class="jiaoyi_box">
          <p><i>结算金额（元）</i></p>
          <p>
            <b>{{ (settleAmount / 100) | formatMoney }}</b>
          </p>
        </div>
      </div>
      <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td style="width: 30px">
              <p>
                <el-checkbox
                  v-model="allSelect"
                  @change="parChange"
                ></el-checkbox>
              </p>
            </td>
            <td>
              <p>申请时间</p>
              <p>审核时间</p>
            </td>
            <td>
              <p>分账商户名称</p>
              <p>分账商户编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>结算流水号</p>
            </td>
            <td>
              <p>结算金额</p>
            </td>
            <td>
              <p>结算账户</p>
              <p>结算账户名</p>
            </td>
            <td style="width: 70px">
              <p>审核状态</p>
            </td>
            <td style="width: 80px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td style="width: 30px">
              <p>
                <el-checkbox
                  v-model="v.active"
                  @change="chidChange"
                ></el-checkbox>
              </p>
            </td>
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.checkTime }}</p>
            </td>
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.plfSettleOrderNo }}</p>
            </td>
            <td>
              <p>{{ (v.settleAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.accountNo }}</p>
              <p>{{ v.accountName }}</p>

            </td>
            <td style="width: 70px">
              <p>{{ checkStatus[v.checkStatus] }}</p>
            </td>
            <td style="width: 80px">
              <p>
                <i
                  v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/DETAIL'"
                  class="lianjie"
                  @click="showTab(v)"
                  >详情</i
                >
              </p>
              <p v-if="v.checkStatus == 'WAIT'">
                <i  v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/PASS'"  class="lianjie"  :disabled="disabledFlag"  @click="setStatus(v, true)" >
                  通过
                </i> 
                <i  v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/REJECT'" :disabled="disabledFlag"
                  @click="setStatus(v, false)"
                  class="lianjie"
                  >驳回</i>
              </p>
            </td>

          </tr>
        </table>
      </div>
      <el-pagination
        ref="pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.pageNo"
        :page-sizes="[10,20,50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNum"
      >
      </el-pagination>
    </div>
    <!-- 按订单号查询 -->
    <div v-if="crumbs == '订单查询'">
      <div class="con_from">
        <div class="lineP">
          <div class="item itemTwo">
            <!-- <i>退款订单号</i> -->
            <el-select
              v-model="formData.orderNoType"
              placeholder=""
              style="width: 170px; margin-right: 20px"
            >
              <el-option value="plfSettleOrderNo" label="结算流水号"></el-option>
            </el-select>
            <el-input
              v-model="formData.orderNo"
              maxlength="128"
              style="width:calc(100% - 170px - 20px)"
              suffix-icon="el-icon-search"
              :placeholder="'搜索' + orderNoType[formData.orderNoType]"
            ></el-input>
          </div>
          <div class="itembtn itemTwobtn">
            <el-button class="searchBt" @click="getDetail">查询</el-button>
          </div>
        </div>
      </div>
       <div class="tab1">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>申请时间</p>
              <p>审核时间</p>
            </td>
            <td>
              <p>分账商户名称</p>
              <p>分账商户编号</p>
            </td>
            <td>
              <p>所属商户</p>
            </td>
            <td>
              <p>结算流水号</p>
            </td>
            <td>
              <p>结算金额</p>
            </td>
            <td>
              <p>结算账户</p>
              <p>结算账户名</p>
            </td>
            <td style="width: 70px">
              <p>审核状态</p>
            </td>
            <td style="width: 80px">
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabData" :key="i">
            <td>
              <p>{{ v.createTime }}</p>
              <p>{{ v.checkTime }}</p>
            </td>
            <td>
              <p>{{ v.allocMchName }}</p>
              <p>{{ v.allocMchId }}</p>
            </td>
            <td>
              <p>{{ v.mchName }}</p>
            </td>
            <td>
              <p>{{ v.plfSettleOrderNo }}</p>
            </td>
            <td>
              <p>{{ (v.settleAmount / 100) | formatMoney }}</p>
            </td>
            <td>
              <p>{{ v.accountNo }}</p>
              <p>{{ v.accountName }}</p>

            </td>
            <td style="width: 70px">
              <p>{{ checkStatus[v.checkStatus] }}</p>
            </td>
             <td style="width: 80px">
              <p>
                <i
                  v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/DETAIL'"
                  class="lianjie"
                  @click="showTab(v)"
                  >详情</i
                >
              </p>
              <p v-if="v.checkStatus == 'WAIT'">
                <i  v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/PASS'"  class="lianjie"  :disabled="disabledFlag"  @click="setStatus(v, true)" >
                  通过
                </i> 
                <i v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/REJECT'" :disabled="disabledFlag"
                  @click="setStatus(v, false)"
                  class="lianjie"
                  >驳回</i>
              </p>
              <!-- <p v-if="v.checkStatus == 'WAIT'">
                <i  v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/PASS'"  class="lianjie"  :disabled="disabledFlag"  @click="setStatus(v, true)" >
                  通过
                </i> 
                <i  v-auth="'TRADE:ALLOC:MANUAL_SGJSSH/REJECT'" :disabled="disabledFlag"
                  @click="setStatus(v, false)"
                  class="lianjie"
                  >驳回</i>
              </p> -->
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/utils/common.js";
import {
  getManualInquiry,
  getManualInquirySearch,
  getManualsetCheck,
} from "@/api/trading/subaccount.js";
import { merchantsDrop , DivisionDrop } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        pageNo: 1,
        pageSize: 10,
        timeType: "CREATE",
        startTime: formatDate(+new Date(), "yyyy-MM-dd 00:00:00"),
        endTime: formatDate(+new Date(), "yyyy-MM-dd 23:59:59"),
        checkStatus: "",
        mchId:"",
        keyword: "",
      },
      checkStatus: {
        WAIT: "待审核",
        REJECT: "已驳回",
        PASS: "已审核",
      },
      totalNum: 0,
      settleAmount:0,      

      formData: {
        orderNoType: "plfSettleOrderNo",
        orderNo: "",
        // accountType:"",
        // account:"",
      },
      orderNoType: {
        plfSettleOrderNo: "结算流水号",
      },
      orderNo: "", //当订单查询输入框的订单号被清空时,缓存被点击的审核或者驳回的商户订单号

      mchDrop: [],

      tabData: [],
      refundStatus: {
        WAIT: "已审核",
        REJECT: "已驳回",
        SUCCESS: "已审核",
        FAIL: "已审核",
        PROCESSING: "退款中",
      },
      
      crumbs: "批量查询",
      allSelect: false,
    
      disabledFlag: false,//防止重复点击
      divisionData: [], //分账商户名称/编号可选项
      keywordData:{
        allocMchId:"",
        allocMchName:"",
      },

    };
  },
  computed: {
    ...mapState({
      refundShDetail: "trading_refund_sgjssh",
    }),
  },
  created() {
    if (this.refundShDetail) {
      this.query = this.refundShDetail.query;
      this.formData = this.refundShDetail.formData;
      this.keywordData = this.refundShDetail.keywordData;
    }
    if(JSON.stringify(this.keywordData) != '{}' && this.keywordData != undefined && this.keywordData.allocMchId != ""){
      this.query.keyword = this.keywordData.allocMchName + ' | ' + this.keywordData.allocMchId;
    }
    this.$nextTick(() => {
      this.$refs.pagination.internalCurrentPage = this.query.pageNo;
    });
    this.getDrop();
    this.getLists(false);
    this.$enter(this.$route.path, this.search);
  },
    methods: {
    ...mapMutations({
      setRefundSh: "trading_setRefund_sgjssh",
    }),
    search() {
      if (this.crumbs == "批量查询") {
        this.getLists();
      } else {
        this.getDetail();
      }
    },
    // 批量查询 表格
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
        // this.query.keyword=this.query.keyword.trim()
      }
      let data = JSON.parse(JSON.stringify(this.query));
      if(this.keywordData != undefined && this.keywordData.allocMchId != ""){
        data.keyword = this.keywordData.allocMchId;
      }
      getManualInquiry(data).then((res) => {
        if (res) {
          if (this.crumbs == "批量查询") {
            this.allSelect = false;
            this.tabData = res.resultData.orders;
            if (this.tabData.length == 0) {
              this.$message({
                message: "暂无数据",
                duration: 1500,
              });
            }
          }
          this.totalNum = res.resultData.totalNum;
          this.settleAmount = res.resultData.settleAmount;
        }
      });
    },

    //  处理计算失真
    accMul(arg1, arg2) {
      var m = 0,
        s1 = arg1.toString(),
        s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    // 全选
    parChange() {
      if (this.allSelect) {
        this.tabData.forEach((v) => {
          v.active = true;
        });
      } else {
        this.tabData.forEach((v) => {
          v.active = false;
        });
      }
      this.tabData = JSON.parse(JSON.stringify(this.tabData));
    },
    //多选
    chidChange() {
      let length = 0;
      this.tabData.forEach((v) => {
        if (v.active) {
          length++;
        } else {
          this.allSelect = false;
        }
      });
      if (length == this.tabData.length) {
        this.allSelect = true;
      }
    },
    // 获取单号 表
    getDetail() {
      let params = {
        orderNoType: this.formData.orderNoType,
        orderNo: this.formData.orderNo.trim(),
      };
      if (params.orderNo) {
        getManualInquirySearch(params).then((res) => {
          if (res) {
            if (this.crumbs == "订单查询") {
              this.tabData = res.resultData;
              if (this.tabData.length == 0) {
                this.$message({
                  message: "暂无数据",
                  duration: 1500,
                });
              }
            }
          }
        });
      } else {
        this.$message.error(
          "请输入" + this.orderNoType[this.formData.orderNoType]
        );
      }
    },
    // 获取下拉
    getDrop() {
      merchantsDrop().then((res) => {
        if (res) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 跳转详情
    showTab(value) {
      this.$router.push({
        name: "refund_sgjssh_xq",
        query: {
          id: value.settleOrderId,
        },
      });
    },
    // 设置通过、驳回
    setStatus(value, bool) {
      // return
      this.orderNo = value.plfSettleOrderNo;
      let data = {
        settleOrderIds: [value.settleOrderId],
        checkStatus: bool ? "PASS" : "REJECT",
      };
      this.disabledFlag = true
      getManualsetCheck(data).then((res) => {
        this.disabledFlag = false;
        if (this.crumbs == "批量查询") {
          this.getLists(false);
        } else {
          let params = {
            orderNoType: "plfSettleOrderNo",
            orderNo: this.orderNo,
          };
          getManualInquirySearch(params).then((res1) => {
            if (res1.resultStatus) {
              if (this.crumbs == "订单查询") {
                this.tabData = res1.resultData;
                if (this.tabData.length == 0) {
                  this.$message({
                    message: "暂无数据",
                    duration: 1500,
                  });
                }
              }
            }
          });
        }
        this.allSelect = true;
        if (res.resultStatus) {
          this.$message.success(bool ? "审核通过" : "审核驳回");
        }
      });
    
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
      this.tabData = [];
      if (data == "批量查询") {
        this.getLists();
      } else {
        if (this.formData.orderNo) {
          this.getDetail();
        }
      }
    },
    // 批量
    setStatusS(bool) {
      let settleOrderIds = [];
      let tabData = [];
      this.tabData.forEach((v) => {
        if (v.active) {
          tabData.push(v);
        }
      });
      // console.log('批量驳回',tabData)
      if (tabData.length <= 0) {
        this.$message.error(
          bool ? "请勾选需要审核的订单" : "请勾选需要驳回的订单"
        );
        return;
      }
      tabData.forEach((v) => {
        if (v.checkStatus == "WAIT") {
          settleOrderIds.push(v.settleOrderId);
        }
      });
      if (settleOrderIds.length <= 0) {
        this.$message.error("勾选的订单已全部审核完毕");
        return;
      }
      let data = {
        settleOrderIds: settleOrderIds,
        checkStatus: bool ? "PASS" : "REJECT",
      };
      this.disabledFlag = true
      getManualsetCheck(data).then((res) => {
        if (res) {
          this.$message.success(bool ? "审核通过" : "审核驳回");
          this.getLists(false);
          this.disabledFlag = false;
          this.tabData = '';
          this.allSelect = false;
        }
      });
    },
    // 获取商户下拉列表
    _searchAllMch(value) {
      this.divisionData = [];
      let params = {
        keyword: value,
      };
      DivisionDrop(params).then((res) => {
        if (res.resultStatus) {
          this.divisionData = res.resultData;
          console.log(this.divisionData);
        }
      });
    },
    // 商户下拉列表输入搜索内容时
    searchMchList(val) {
      if (val) {
        let value = val;
        this._searchAllMch(value);
      } else {
        // 搜索内容为空时还原列表
        this._searchAllMch();
      }
    },
    // 商户下拉框展开/收起时触发
    unfoldMchList(e) {
      if (e) {
        //  展开时
        this._searchAllMch();
      } else {
        //  折叠时
        this.divisionData = [];
      }
    },
    // 商户下拉搜索框清空搜索内容时
    clearMchList() {
      this.query.keyword = ""; //清空选中的商户id
      this.keywordData = {};
      this._searchAllMch(); //重新获取所有商户下拉列表
    },
    changeMch(e) {
      this.query.keyword = e;
      let allocMchId  = this.query.keyword;
      console.log( this.query.keyword );
      let result = this.divisionData.filter(e=>e.allocMchId === allocMchId)
      if(result && result.length > 0){
        console.log(result[0])
        this.keywordData = result[0];
      }
    }, 
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
      formData: this.formData,
      keywordData: this.keywordData,
    };
    this.setRefundSh(data);
  },
};
</script>
<style scoped>
.con_from .lineP .itemTime{
  width: 63.5%;
}
.con_from .lineP .item i {
  width: 64px;
}

.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 64px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 130px - 64px - 44.67px - 10px) / 2);
}
.jiaoyi_box{
  width: 49%;
}
</style>
